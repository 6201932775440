import React from 'react';
import { motion } from 'framer-motion';
import { Feather, Shield, Zap, Users, ChartBar, Rocket, ExternalLink, Scan } from 'lucide-react';
import { Link } from 'react-router-dom';

const FeatureCard = ({ title, description, icon: Icon }) => (
  <motion.div
    className="bg-gray-800 rounded-lg p-6 shadow-lg"
    whileHover={{ scale: 1.05 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <Icon className="text-orange-500 mb-4" size={36} />
    <h3 className="text-xl font-bold text-white mb-2">{title}</h3>
    <p className="text-gray-300">{description}</p>
  </motion.div>
);

const TierCard = ({ title, threshold, gradient }) => (
  <motion.div
    className={`${gradient} rounded-lg p-6 shadow-lg`}
    whileHover={{ scale: 1.05 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <h3 className="text-2xl font-bold text-white mb-2">{title}</h3>
    <p className="text-white">Threshold: {threshold} ICA</p>
  </motion.div>
);

const IcarusIntroduction = () => {
  return (
    <div className="bg-gray-900 text-white min-h-screen p-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h1 className="text-4xl font-bold text-orange-500 mb-8 flex items-center">
          <Feather className="mr-4" size={48} /> Welcome to ICARUS
        </h1>

        <div className="mb-12">
          <h2 className="text-3xl font-bold text-white mb-4">Unlock the Power of ICARUS</h2>
          <p className="text-xl text-gray-300 mb-6">
            To access our advanced toolkit and exclusive features, you need to achieve one of the following tiers:
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
            <TierCard title="Flock" threshold="1,000,000,000" gradient="bg-gradient-to-r from-blue-500 to-blue-700" />
            <TierCard title="Soar" threshold="5,000,000,000" gradient="bg-gradient-to-r from-green-500 to-green-700" />
            <TierCard title="CORE" threshold="9,500,000,000" gradient="bg-gradient-to-r from-yellow-500 to-orange-500" />
          </div>
        </div>

        <div className="mb-12">
          <h2 className="text-3xl font-bold text-white mb-6">Why Join ICARUS?</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <FeatureCard
              icon={Shield}
              title="Enhanced Security"
              description="Benefit from our advanced security measures and protect your investments."
            />
            <FeatureCard
              icon={Zap}
              title="Exclusive Features"
              description="Access cutting-edge tools and analytics available only to ICARUS members."
            />
            <FeatureCard
              icon={Users}
              title="Vibrant Community"
              description="Join a thriving ecosystem of like-minded investors and innovators."
            />
            <FeatureCard
              icon={ChartBar}
              title="Advanced Analytics"
              description="Gain insights with our state-of-the-art data analysis and visualization tools."
            />
            <FeatureCard
              icon={Rocket}
              title="Early Access"
              description="Be the first to know about new features, partnerships, and investment opportunities."
            />
          </div>
        </div>

        <div className="mb-12">
          <h2 className="text-3xl font-bold text-white mb-6 flex items-center">
            <Scan className="mr-2" size={36} /> Introducing the Project Scanner
          </h2>
          <p className="text-xl text-gray-300 mb-6">
            Our Project Scanner is a game-changing tool designed to give you an edge in the crypto market. Here's what it offers:
          </p>
          <ul className="list-disc list-inside text-gray-300 space-y-2 mb-6">
            <li>Real-time monitoring of new token launches on the Alvey Chain</li>
            <li>Comprehensive risk analysis and scoring for new projects</li>
            <li>Detailed contract audits and liquidity pool analysis</li>
            <li>Early detection of potential rug pulls and scams</li>
            <li>Customizable alerts for promising new projects</li>
          </ul>
          <p className="text-xl text-gray-300 mb-6">
            With the Project Scanner, you'll have a powerful ally in identifying promising opportunities and avoiding potential pitfalls in the fast-paced world of cryptocurrency.
          </p>
        </div>

        <div className="text-center">
          <Link
            to="/dashboard"
            className="bg-gradient-to-r from-orange-500 to-yellow-500 text-white font-bold py-3 px-8 rounded-full inline-flex items-center transition-colors duration-200 hover:from-orange-600 hover:to-yellow-600"
          >
            Get Started with ICARUS <ExternalLink size={20} className="ml-2" />
          </Link>
        </div>
      </motion.div>
    </div>
  );
};

export default IcarusIntroduction;