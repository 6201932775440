export const formatNumber = (value, type) => {
  const num = parseFloat(value);
  switch (type) {
    case 'dollar':
    case 'USD':
      return `$${num.toFixed(2)}`;
    case 'dollarRound':
      return `$${Math.round(num).toLocaleString()}`;
    case 'ICA':
      if (num >= 1e9) {
        return `${(num / 1e9).toFixed(2)}B ICA`;
      } else if (num >= 1e6) {
        return `${(num / 1e6).toFixed(2)}M ICA`;
      } else if (num >= 1e3) {
        return `${(num / 1e3).toFixed(2)}K ICA`;
      } else {
        return `${num.toFixed(2)} ICA`;
      }
    case 'BNB':
      return `${num.toFixed(5)} BNB`;
    case 'ALV':
      return `${num.toFixed(3)} ALV`;
    case 'ICAPrice':
      return `$${num.toFixed(10)}`;
    case 'lowPriceUSD':
      return `$${num.toFixed(Math.max(3, -Math.floor(Math.log10(num)) + 2))}`;
    case 'percent':
      return `${num.toFixed(2)}%`;
    default:
      return num.toLocaleString();
  }
};

export const formatAddress = (address) => {
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};

export const formatNumberCompact = (num) => {
  const absNum = Math.abs(num);
  if (absNum >= 1e9) return (num / 1e9).toFixed(1) + 'B';
  if (absNum >= 1e6) return (num / 1e6).toFixed(1) + 'M';
  if (absNum >= 1e3) return (num / 1e3).toFixed(1) + 'K';
  return num.toFixed(2);
};