import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import { formatNumber } from '../utils/numberUtils';
import { BarChart, Coins, Flame, Users, DollarSign, TrendingUp, Droplet, PieChart as PieChartIcon, Lock, Clock, GiftIcon } from 'lucide-react';
import { config } from '../config';
import { fetchTokenHoldings, fetchTotalSupply, fetch24hData } from '../utils/geckoTerminalUtils';
import { fetchTokenPrice } from '../utils/moralisUtils';
import { ethers } from 'ethers';
import { ICARUS_ABI } from '../abi';

const COLORS = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F7DC6F'];

const InfoCard = ({ title, value, icon: Icon, tooltip, className }) => (
  <motion.div 
    className={`bg-gray-800 rounded-lg p-4 shadow relative group ${className}`}
    whileHover={{ scale: 1.05 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <h3 className="text-sm font-bold text-white mb-1 flex items-center">
      <Icon className="mr-2 h-4 w-4" /> {title}
    </h3>
    <p className="text-2xl font-bold text-white">{value}</p>
    {tooltip && (
      <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-900 text-white text-xs rounded py-1 px-2 hidden group-hover:block">
        {tooltip}
      </div>
    )}
  </motion.div>
);

const ProjectOverview = () => {
  const [tokenData, setTokenData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          totalSupply,
          priceData,
          walletHoldings,
          data24h,
          totalDistributed
        ] = await Promise.all([
          fetchTotalSupply(),
          fetchTokenPrice(),
          fetchTokenHoldings([
            ...config.stakingWallets,
            ...Object.values(config.teamWallets),
            config.icaPoolPairAddress,
            '0x000000000000000000000000000000000000dead',
            config.lockedWallet
          ]),
          fetch24hData(),
          fetchTotalDistributed()
        ]);

        const burnedTokens = walletHoldings.find(h => h.address.toLowerCase() === '0x000000000000000000000000000000000000dead')?.balance || '0';
        const liquidityTokens = walletHoldings.find(h => h.address.toLowerCase() === config.icaPoolPairAddress.toLowerCase())?.balance || '0';
        const stakedTokens = config.stakingWallets.reduce((acc, addr) => 
          acc + parseFloat(walletHoldings.find(h => h.address.toLowerCase() === addr.toLowerCase())?.balance || '0'), 0);
        const teamTokens = Object.values(config.teamWallets).reduce((acc, addr) => 
          acc + parseFloat(walletHoldings.find(h => h.address.toLowerCase() === addr.toLowerCase())?.balance || '0'), 0);
        const lockedTokens = parseFloat(walletHoldings.find(h => h.address.toLowerCase() === config.lockedWallet.toLowerCase())?.balance || '0');

        const trueCirculatingSupply = parseFloat(totalSupply) - parseFloat(burnedTokens) - parseFloat(liquidityTokens) - stakedTokens - teamTokens - lockedTokens;

        setTokenData({
          totalSupply: parseFloat(totalSupply),
          burnedTokens: parseFloat(burnedTokens),
          liquidityTokens: parseFloat(liquidityTokens),
          stakedTokens,
          teamTokens,
          lockedTokens,
          trueCirculatingSupply,
          price: priceData.usdPrice,
          priceChange24h: priceData.percentChange24h,
          marketCap: trueCirculatingSupply * priceData.usdPrice,
          liquidity: priceData.pairTotalLiquidityUsd,
          volume24h: data24h.volume24h,
          totalDistributed
        });
      } catch (err) {
        console.error('Error fetching token data:', err);
        setError(`Failed to fetch some token data. Displaying available information.`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, config.apiCacheDuration);
    return () => clearInterval(interval);
  }, []);

  const fetchTotalDistributed = async () => {
    const provider = new ethers.providers.JsonRpcProvider(config.bscRpcUrl);
    const contract = new ethers.Contract(config.contractAddress, ICARUS_ABI, provider);
    const totalDistributed = await contract.totalDistributedAll();
    return ethers.utils.formatEther(totalDistributed);
  };

  if (isLoading) return <div className="text-center p-4">Loading project overview...</div>;

  const pieChartData = [
    { name: 'Burned', value: tokenData.burnedTokens },
    { name: 'Liquidity', value: tokenData.liquidityTokens },
    { name: 'Staked', value: tokenData.stakedTokens },
    { name: 'Team Controlled', value: tokenData.teamTokens },
    { name: 'Locked', value: tokenData.lockedTokens },
    { name: 'Circulating', value: tokenData.trueCirculatingSupply },
  ];

  return (
    <section className="bg-gray-900 rounded-lg shadow-md p-6">
      <h2 className="text-2xl font-bold mb-6 text-orange-500 flex items-center">
        <BarChart className="mr-2" /> Project Overview BSC
      </h2>
      {error && <div className="text-yellow-500 mb-4">{error}</div>}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mb-6">
        <InfoCard 
          title="Current Price" 
          value={formatNumber(tokenData?.price || 0, 'ICAPrice')} 
          icon={DollarSign} 
          className="bg-gradient-to-br from-orange-500 to-yellow-500"
        />
        <InfoCard 
          title="True Circulating Supply" 
          value={formatNumber(tokenData?.trueCirculatingSupply, 'ICA')} 
          icon={TrendingUp} 
          tooltip="Total Supply - Burned - Liquidity - Staked - Team Controlled - Locked"
        />
        <InfoCard 
          title="True Market Cap" 
          value={formatNumber(tokenData?.marketCap || 0, 'dollar')} 
          icon={PieChartIcon}
          tooltip="Calculated using the True Circulating Supply"
        />
        <InfoCard 
          title="24h Price Change" 
          value={`${formatNumber(tokenData?.priceChange24h || 0, 'percent')}`} 
          icon={Clock}
          className={tokenData?.priceChange24h >= 0 ? 'bg-gradient-to-br from-green-500 to-green-700' : 'bg-gradient-to-br from-red-500 to-red-700'}
        />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div>
          <h3 className="text-xl font-bold text-orange-500 mb-4">Token Distribution</h3>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={pieChartData}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {pieChartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip formatter={(value) => formatNumber(value, 'ICA')} />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <InfoCard title="Total Supply" value={formatNumber(tokenData?.totalSupply, 'ICA')} icon={Coins} />
          <InfoCard title="Burned Tokens" value={formatNumber(tokenData?.burnedTokens, 'ICA')} icon={Flame} />
          <InfoCard title="Liquidity Tokens" value={formatNumber(tokenData?.liquidityTokens, 'ICA')} icon={Droplet} />
          <InfoCard title="Staked ICA" value={formatNumber(tokenData?.stakedTokens, 'ICA')} icon={Lock} />
          <InfoCard title="Team Controlled ICA" value={formatNumber(tokenData?.teamTokens, 'ICA')} icon={Users} />
          <InfoCard title="Locked ICA" value={formatNumber(tokenData?.lockedTokens, 'ICA')} icon={Lock} />
          <InfoCard title="Total Distributed BNB" value={formatNumber(tokenData?.totalDistributed || 0, 'BNB')} icon={GiftIcon} className="bg-gradient-to-r from-blue-600 to-purple-600"/>
          <InfoCard title="Liquidity" value={formatNumber(tokenData?.liquidity || 0, 'dollar')} icon={Droplet} />
        </div>
      </div>
    </section>
  );
};

export default ProjectOverview;