import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { MessageCircle, Rocket, Gamepad, DollarSign, Users, BookOpen, ExternalLink, Award, Zap } from 'lucide-react';
import { config } from '../config';
import LoadingAnimation from './LoadingAnimation';

const EventTypeDescription = ({ title, description, icon: Icon, banner }) => (
  <motion.div 
    className="bg-gray-800 rounded-lg shadow-lg overflow-hidden"
    whileHover={{ scale: 1.02 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <img src={banner} alt={title} className="w-full h-48 object-cover" />
    <div className="p-6">
      <div className="flex items-center mb-4">
        <Icon className="text-orange-500 mr-4" size={32} />
        <h3 className="text-2xl font-bold text-white">{title}</h3>
      </div>
      <p className="text-gray-300">{description}</p>
    </div>
  </motion.div>
);

const PromotionalCard = ({ title, description, icon: Icon, link, linkText }) => (
  <motion.div
    className="bg-gray-800 rounded-lg shadow-lg p-6 mb-4"
    whileHover={{ scale: 1.05 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <div className="flex items-center mb-4">
      <Icon className="text-orange-500 mr-4" size={24} />
      <h3 className="text-xl font-bold text-white">{title}</h3>
    </div>
    <p className="text-gray-300 mb-4">{description}</p>
    <div className="flex justify-end">
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded inline-flex items-center transition-colors duration-200"
      >
        {linkText} <ExternalLink size={16} className="ml-2" />
      </a>
    </div>
  </motion.div>
);

const TelegramCard = () => (
  <motion.div
    className="bg-gradient-to-r from-blue-600 to-purple-600 rounded-lg shadow-lg p-6 mb-4"
    whileHover={{ scale: 1.05 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <div className="flex items-center mb-4">
      <MessageCircle className="text-white mr-4" size={24} />
      <h3 className="text-xl font-bold text-white">Join Our Telegram</h3>
    </div>
    <p className="text-white mb-4">Connect with the ICARUS community, participate in discussions, and stay updated on the latest news and events!</p>
    <div className="flex justify-end">
      <a
        href="https://t.me/ICARUS_Portal"
        target="_blank"
        rel="noopener noreferrer"
        className="bg-white text-blue-600 font-bold py-2 px-4 rounded inline-flex items-center transition-colors duration-200 hover:bg-gray-100"
      >
        Join Telegram <ExternalLink size={16} className="ml-2" />
      </a>
    </div>
  </motion.div>
);

const IntroductionCard = () => (
  <motion.div 
    className="bg-gray-800 rounded-lg shadow-lg overflow-hidden mb-8"
    whileHover={{ scale: 1.02 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <div className="flex flex-col md:flex-row">
      <div className="md:w-1/2 p-6">
        <h2 className="text-2xl font-bold text-orange-500 mb-4">Welcome to ICARUS Events</h2>
        <p className="text-gray-300">
          At ICARUS, we believe in creating a robust, engaging environment where community members can connect, learn, and grow together. Our event lineup is crafted with the dual goals of enhancing user experience and fostering a deeper understanding of the crypto ecosystem. Here's what our participants can look forward to:
        </p>
      </div>
      <div className="md:w-1/2">
        <img src="/bridge.png" alt="ICARUS Bridge" className="w-full h-full object-cover" />
      </div>
    </div>
  </motion.div>
);

const Events = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <LoadingAnimation />;
  }

  return (
    <div className="w-full px-4 sm:px-6 lg:px-8 py-8">
      <div className="flex flex-col lg:flex-row gap-8">
        <div className="lg:w-3/4">
          <IntroductionCard />

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
            <EventTypeDescription
              title="Chat2Earn"
              description="Dive into our vibrant community through our Chat2Earn program on Telegram. This initiative rewards active participation with $ICA tokens."
              icon={MessageCircle}
              banner="/banner-chat2earn.png"
            />
            <EventTypeDescription
              title="Raiding"
              description="Participate in our raiding events where the community comes together to increase the visibility and impact of ICARUS in the crypto landscape."
              icon={Rocket}
              banner="/banner-raid2.png"
            />
            <EventTypeDescription
              title="Gaming"
              description="Merge the thrill of gaming with blockchain technology. Our gaming events come with the potential to earn crypto rewards."
              icon={Gamepad}
              banner="/banner-games.png"
            />
            <EventTypeDescription
              title="Buy Comps"
              description="Challenge yourself in Buy Comps, where your investment acumen can lead to substantial rewards based on timing and market strategies."
              icon={DollarSign}
              banner="/banner-buycomp.png"
            />
            <EventTypeDescription
              title="AMA Sessions"
              description="Gain exclusive insights during our AMA sessions, featuring industry experts, crypto influencers, and the ICARUS development team."
              icon={Users}
              banner="/banner-ama.png"
            />
            <EventTypeDescription
              title="Project Reviews"
              description="Our in-depth project reviews provide critical analysis of both burgeoning and established crypto projects."
              icon={Zap}
              banner="/banner-science.png"
            />
            <EventTypeDescription
              title="Educational Content"
              description="Empower yourself with our extensive range of educational content that caters to all levels of crypto enthusiasts."
              icon={BookOpen}
              banner="/banner-edu.png"
            />
            <motion.div 
              className="bg-gradient-to-r from-orange-500 to-yellow-500 rounded-lg shadow-lg overflow-hidden"
              whileHover={{ scale: 1.02 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <div className="p-6">
                <h2 className="text-2xl font-bold text-white mb-4">Get Involved!</h2>
                <p className="text-white mb-6">
                  Are you ready to deepen your engagement with the crypto world? Join ICARUS today and start your journey. Each event is designed to support your growth in the crypto space and to enhance your understanding and enjoyment of this dynamic field.
                </p>
                <div className="flex justify-end">
                  <a
                    href="https://t.me/ICARUS_Portal"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-white text-orange-500 font-bold py-3 px-6 rounded-full inline-flex items-center transition-colors duration-200 hover:bg-gray-100"
                  >
                    Join ICARUS Community <ExternalLink size={20} className="ml-2" />
                  </a>
                </div>
              </div>
            </motion.div>
          </div>
        </div>

        <div className="lg:w-1/4">
          <TelegramCard />
          <PromotionalCard
            title="Current Chat2Earn Campaign"
            description="Join our ongoing Chat2Earn campaign and start earning $ICA tokens today!"
            icon={MessageCircle}
            link="#"
            linkText="Join Campaign"
          />
          <PromotionalCard
            title="KOL Program"
            description="Become a Key Opinion Leader for ICARUS and unlock exclusive benefits."
            icon={Award}
            link={`https://zealy.io/c/${config.zealyApiUrl.split('/').pop()}`}
            linkText="Join Zealy"
          />
        </div>
      </div>
    </div>
  );
};

export default Events;