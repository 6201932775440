import React, { useEffect } from 'react';
import { useAccount, useBalance, useContractRead, useNetwork, useContractWrite, usePrepareContractWrite } from 'wagmi';
import { formatEther } from 'viem';
import { ICARUS_ABI } from '../abi';
import { ALVEY_ABI } from '../utils/alveyAbi';
import { config } from '../config';
import { formatNumber } from '../utils/numberUtils';
import { Wallet, CreditCard, Droplet, Clock, AlertTriangle, ExternalLink, GiftIcon, PieChart } from 'lucide-react';
import { motion } from 'framer-motion';

const UserContractInfo = () => {
  const { address, isConnected } = useAccount();
  const { chain } = useNetwork();

  const bscChainId = 56;
  const alveyChainId = 3797;

  const logError = (functionName, error) => {
    console.error(`Error in ${functionName}:`, error);
    console.error('Error details:', {
      message: error.message,
      code: error.code,
      data: error.data,
      method: error.method,
      transaction: error.transaction,
    });
  };

  // BSC Calls
  const { data: bscBalance, isError: isBscBalanceError, error: bscBalanceError } = useBalance({
    address,
    token: config.contractAddress,
    chainId: bscChainId,
    onError: (error) => logError('bscBalance', error),
  });

  const { data: bscDistributed, isError: isBscDistributedError, error: bscDistributedError } = useContractRead({
    address: config.contractAddress,
    abi: ICARUS_ABI,
    functionName: 'totalDistributedPerPerson',
    args: [address],
    chainId: bscChainId,
    onError: (error) => logError('bscDistributed', error),
  });

  const { data: bscPending, isError: isBscPendingError, error: bscPendingError } = useContractRead({
    address: config.contractAddress,
    abi: ICARUS_ABI,
    functionName: 'pendingRewards',
    args: [address],
    chainId: bscChainId,
    onError: (error) => logError('bscPending', error),
  });

  const { data: bscTotalDistributed, isError: isBscTotalDistributedError, error: bscTotalDistributedError } = useContractRead({
    address: config.contractAddress,
    abi: ICARUS_ABI,
    functionName: 'totalDistributedAll',
    chainId: bscChainId,
    onError: (error) => logError('bscTotalDistributed', error),
  });

  // Alvey Calls
  const { data: alveyBalance, isError: isAlveyBalanceError, error: alveyBalanceError } = useBalance({
    address,
    token: config.alveyContractAddress,
    chainId: alveyChainId,
    onError: (error) => logError('alveyBalance', error),
  });

  const { data: alveyDistributed, isError: isAlveyDistributedError, error: alveyDistributedError } = useContractRead({
    address: config.alveyContractAddress,
    abi: ALVEY_ABI,
    functionName: 'totalDistributedPerPerson',
    args: [address],
    chainId: alveyChainId,
    onError: (error) => logError('alveyDistributed', error),
  });

  const { data: alveyPending, isError: isAlveyPendingError, error: alveyPendingError } = useContractRead({
    address: config.alveyContractAddress,
    abi: ALVEY_ABI,
    functionName: 'pendingRewards',
    args: [address],
    chainId: alveyChainId,
    onError: (error) => logError('alveyPending', error),
  });

  const { data: alveyTotalDistributed, isError: isAlveyTotalDistributedError, error: alveyTotalDistributedError } = useContractRead({
    address: config.alveyContractAddress,
    abi: ALVEY_ABI,
    functionName: 'totalDistributedAll',
    chainId: alveyChainId,
    onError: (error) => logError('alveyTotalDistributed', error),
  });

  // Distribution functions
  const { config: bscDistributeConfig } = usePrepareContractWrite({
    address: config.contractAddress,
    abi: ICARUS_ABI,
    functionName: 'claimDividend',
    chainId: bscChainId,
  });

  const { write: bscDistribute, isLoading: isBscDistributing } = useContractWrite(bscDistributeConfig);

  const { config: alveyDistributeConfig } = usePrepareContractWrite({
    address: config.alveyContractAddress,
    abi: ALVEY_ABI,
    functionName: 'claimDividend',
    chainId: alveyChainId,
  });

  const { write: alveyDistribute, isLoading: isAlveyDistributing } = useContractWrite(alveyDistributeConfig);

  useEffect(() => {
    console.log('BSC Balance:', bscBalance);
    console.log('BSC Distributed:', bscDistributed);
    console.log('BSC Pending:', bscPending);
    console.log('BSC Total Distributed:', bscTotalDistributed);
    console.log('Alvey Balance:', alveyBalance);
    console.log('Alvey Distributed:', alveyDistributed);
    console.log('Alvey Pending:', alveyPending);
    console.log('Alvey Total Distributed:', alveyTotalDistributed);

    if (isBscBalanceError) console.error('BSC Balance Error:', bscBalanceError);
    if (isBscDistributedError) console.error('BSC Distributed Error:', bscDistributedError);
    if (isBscPendingError) console.error('BSC Pending Error:', bscPendingError);
    if (isBscTotalDistributedError) console.error('BSC Total Distributed Error:', bscTotalDistributedError);
    if (isAlveyBalanceError) console.error('Alvey Balance Error:', alveyBalanceError);
    if (isAlveyDistributedError) console.error('Alvey Distributed Error:', alveyDistributedError);
    if (isAlveyPendingError) console.error('Alvey Pending Error:', alveyPendingError);
    if (isAlveyTotalDistributedError) console.error('Alvey Total Distributed Error:', alveyTotalDistributedError);
  }, [bscBalance, bscDistributed, bscPending, bscTotalDistributed, 
      alveyBalance, alveyDistributed, alveyPending, alveyTotalDistributed, 
      isBscBalanceError, isBscDistributedError, isBscPendingError, isBscTotalDistributedError,
      isAlveyBalanceError, isAlveyDistributedError, isAlveyPendingError, isAlveyTotalDistributedError]);

  const WalletInfoCard = ({ title, value, icon: Icon, className, error, onDistribute, isDistributing }) => (
    <div className={`bg-gray-800 rounded-lg p-4 shadow mb-4 ${className}`}>
      <div className="flex justify-between items-start">
        <h3 className="text-sm font-bold text-white mb-1 flex items-center">
          <Icon className="mr-2 h-4 w-4" />
          {title}
        </h3>
        {onDistribute && (
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={onDistribute}
            disabled={isDistributing}
            className="bg-orange-500 hover:bg-orange-600 text-white rounded-full p-1"
          >
            <GiftIcon size={16} />
          </motion.button>
        )}
      </div>
      {error ? (
        <p className="text-red-500 text-sm">Error loading data</p>
      ) : (
        <p className="text-lg font-bold text-white">{value}</p>
      )}
    </div>
  );

  const renderChainSection = (chainName, logo, balance, distributed, pending, totalDistributed, isBalanceError, isDistributedError, isPendingError, isTotalDistributedError, buyLink, buyText, onDistribute, isDistributing) => (
    <div className="bg-gray-800 rounded-lg p-6 shadow-lg">
      <h3 className="text-xl font-bold text-orange-500 mb-4 flex items-center">
        <img src={logo} alt={`${chainName} Logo`} className="w-6 h-6 mr-2" />
        {chainName} Chain
      </h3>
      <WalletInfoCard 
        title={`ICA Balance ${chainName}`}
        value={formatNumber(balance ? formatEther(balance.value) : '0', 'ICA')} 
        icon={CreditCard} 
        className="bg-gradient-to-br from-orange-500 to-yellow-500"
        error={isBalanceError}
      />
      <WalletInfoCard 
        title={`Your Distributed ${chainName === 'BSC' ? 'BNB' : 'ALV'}`}
        value={formatNumber(distributed ? formatEther(distributed) : '0', chainName === 'BSC' ? 'BNB' : 'ALV')} 
        icon={GiftIcon} 
        className="bg-gradient-to-br from-blue-500 to-purple-500"
        error={isDistributedError}
      />
      <WalletInfoCard 
        title={`Pending ${chainName === 'BSC' ? 'BNB' : 'ALV'}`}
        value={formatNumber(pending ? formatEther(pending) : '0', chainName === 'BSC' ? 'BNB' : 'ALV')} 
        icon={Clock} 
        className="bg-gradient-to-br from-green-500 to-teal-500"
        error={isPendingError}
        onDistribute={onDistribute}
        isDistributing={isDistributing}
      />
      <WalletInfoCard 
        title={`Total Distributed ${chainName === 'BSC' ? 'BNB' : 'ALV'}`}
        value={formatNumber(totalDistributed ? formatEther(totalDistributed) : '0', chainName === 'BSC' ? 'BNB' : 'ALV')} 
        icon={PieChart} 
        className="bg-gradient-to-br from-purple-500 to-indigo-500"
        error={isTotalDistributedError}
      />
      <a
        href={buyLink}
        target="_blank"
        rel="noopener noreferrer"
        className="block w-full text-center bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 text-white font-bold py-2 px-4 rounded mt-4 transition duration-300 ease-in-out flex items-center justify-center"
      >
        {buyText} <ExternalLink size={16} className="ml-2" />
      </a>
    </div>
  );

  if (!isConnected) {
    return (
      <div className="bg-yellow-900 border-l-4 border-yellow-400 p-4 mb-8">
        <AlertTriangle className="h-5 w-5 text-yellow-400 mr-2 inline" />
        <span className="text-yellow-300">Please connect your wallet to view your information.</span>
      </div>
    );
  }

  return (
    <div className="bg-gray-900 rounded-lg shadow-md p-6">
      <h2 className="text-2xl font-bold mb-6 text-orange-500 flex items-center">
        <Wallet className="mr-2" /> Wallet Information
      </h2>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {renderChainSection(
          'BSC',
          '/bnb-logo.png',
          bscBalance,
          bscDistributed,
          bscPending,
          bscTotalDistributed,
          isBscBalanceError,
          isBscDistributedError,
          isBscPendingError,
          isBscTotalDistributedError,
          "https://pancakeswap.finance/swap?outputCurrency=0xF012F402747E1E4715b7E496650243A29be204C7",
          "BUY ICA BSC",
          bscDistribute,
          isBscDistributing
        )}
        {renderChainSection(
          'Alvey',
          '/alvey-logo.png',
          alveyBalance,
          alveyDistributed,
          alveyPending,
          alveyTotalDistributed,
          isAlveyBalanceError,
          isAlveyDistributedError,
          isAlveyPendingError,
          isAlveyTotalDistributedError,
          "https://elvesdex.com/swap?outputCurrency=0x34195F8DfD55b0ABd159001FF5adE6E284bb0FDD",
          "BUY ICA ALV",
          alveyDistribute,
          isAlveyDistributing
        )}
      </div>
    </div>
  );
};

export default UserContractInfo;