import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Users, Star, Trophy, Rocket, Gift, Coins, ChartBar, MessageCircle, ExternalLink, Award, Target, CheckCircle } from 'lucide-react';
import { useAccount } from 'wagmi';
import { config } from '../config';
import LoadingAnimation from './LoadingAnimation';

const KOL = () => {
  const [zealyData, setZealyData] = useState(null);
  const [userStats, setUserStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const { address } = useAccount();

useEffect(() => {
  const fetchZealyData = async () => {
    try {
      const headers = {
        'Authorization': `Bearer ${config.zealyApiKey}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      };

      const [modulesResponse, questsResponse] = await Promise.all([
        fetch(`${config.zealyApiUrl}/modules`, { headers, mode: 'cors' }),
        fetch(`${config.zealyApiUrl}/quests`, { headers, mode: 'cors' })
      ]);

      const modules = await modulesResponse.json();
      const quests = await questsResponse.json();

      setZealyData({ modules, quests });

      if (address) {
        const userStatsResponse = await fetch(`${config.zealyApiUrl}/users/${address}`, { headers, mode: 'cors' });
        const userStats = await userStatsResponse.json();
        setUserStats(userStats);
      }

      setLoading(false);
    } catch (error) {
      console.error('Error fetching Zealy data:', error);
      setLoading(false);
    }
  };

  fetchZealyData();
}, [address]);

  const ModuleCard = ({ module }) => (
    <motion.div
      className="bg-gray-800 rounded-lg shadow-lg p-6 mb-6"
      whileHover={{ scale: 1.05 }}
      transition={{ type: "spring", stiffness: 300 }}
    >
      <h3 className="text-2xl font-bold text-orange-500 mb-4">{module.name}</h3>
      <p className="text-gray-300 mb-4">{module.description}</p>
      <div className="flex justify-between items-center">
        <span className="text-gray-400 flex items-center">
          <Star className="mr-2" size={16} />
          {module.xp} XP
        </span>
        <a
          href={`https://zealy.io/cw/icaruscommunity/module/${module.id}`}
          target="_blank"
          rel="noopener noreferrer"
          className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded transition-colors duration-200"
        >
          View Module
        </a>
      </div>
    </motion.div>
  );

  const QuestCard = ({ quest }) => (
    <motion.div
      className="bg-gray-700 rounded-lg shadow-lg p-4 mb-4"
      whileHover={{ scale: 1.03 }}
      transition={{ type: "spring", stiffness: 300 }}
    >
      <h4 className="text-xl font-bold text-orange-400 mb-2">{quest.name}</h4>
      <p className="text-gray-300 mb-2">{quest.description}</p>
      <div className="flex justify-between items-center">
        <span className="text-gray-400 flex items-center">
          <Award className="mr-2" size={16} />
          {quest.xp} XP
        </span>
        <a
          href={`https://zealy.io/cw/icaruscommunity/quest/${quest.id}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-orange-500 hover:text-orange-600 font-bold transition-colors duration-200"
        >
          Complete Quest
        </a>
      </div>
    </motion.div>
  );

  const UserStatsBar = ({ stats }) => (
    <div className="bg-gray-800 rounded-lg shadow-lg p-6 mb-6">
      <h3 className="text-2xl font-bold text-orange-500 mb-4">Your KOL Stats</h3>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        <div className="text-center">
          <p className="text-gray-400">Total XP</p>
          <p className="text-2xl font-bold text-white">{stats.xp}</p>
        </div>
        <div className="text-center">
          <p className="text-gray-400">Completed Quests</p>
          <p className="text-2xl font-bold text-white">{stats.completedQuests}</p>
        </div>
        <div className="text-center">
          <p className="text-gray-400">Leaderboard Position</p>
          <p className="text-2xl font-bold text-white">#{stats.leaderboardPosition}</p>
        </div>
        <div className="text-center">
          <p className="text-gray-400">Rewards Earned</p>
          <p className="text-2xl font-bold text-white">{stats.rewardsEarned}</p>
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-full">
      <h1 className="text-4xl font-bold text-orange-500 mb-8 flex items-center">
        <Users className="mr-2" size={36} /> ICARUS Key Opinion Leaders (KOL) Program
      </h1>

      {loading ? (
        <LoadingAnimation />
      ) : (
        <>
          {userStats && <UserStatsBar stats={userStats} />}

          <div className="flex flex-col lg:flex-row gap-8">
            <div className="lg:w-2/3">
              <h2 className="text-3xl font-bold text-orange-500 mb-6 flex items-center">
                <Target className="mr-2" size={28} /> Available Quests
              </h2>
              {zealyData?.quests.map(quest => (
                <QuestCard key={quest.id} quest={quest} />
              ))}
            </div>
            <div className="lg:w-1/3">
              <h2 className="text-3xl font-bold text-orange-500 mb-6 flex items-center">
                <Trophy className="mr-2" size={28} /> KOL Modules
              </h2>
              {zealyData?.modules.map(module => (
                <ModuleCard key={module.id} module={module} />
              ))}
              <div className="bg-gradient-to-r from-orange-500 to-yellow-500 rounded-lg shadow-lg p-6 mt-6">
                <h3 className="text-2xl font-bold text-white mb-4">Join ICARUS KOL Program</h3>
                <p className="text-white mb-4">Become an ICARUS Key Opinion Leader and enjoy exclusive benefits, rewards, and opportunities!</p>
                <a
                  href="https://zealy.io/cw/icaruscommunity"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-white text-orange-500 hover:bg-gray-100 font-bold py-2 px-4 rounded inline-flex items-center transition-colors duration-200"
                >
                  Get Started <ExternalLink size={16} className="ml-2" />
                </a>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default KOL;