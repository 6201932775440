import React, { useState, useEffect } from 'react';
import { useAccount, useSignMessage } from 'wagmi';
import { motion } from 'framer-motion';
import { VoteIcon, Archive, ExternalLink, ThumbsUp, ThumbsDown, Clock, Users, AlertTriangle, ChevronDown, ChevronUp, BarChart2, UserCheck, Activity } from 'lucide-react';
import LoadingAnimation from './LoadingAnimation';

const SNAPSHOT_API_URL = 'https://hub.snapshot.org/graphql';
const SNAPSHOT_SPACE = 'icaruz.eth';

const UtilityCard = ({ title, value, description, icon: Icon, className }) => (
  <motion.div
    className={`bg-gray-800 rounded-lg shadow-lg p-4 mb-4 ${className}`}
    whileHover={{ scale: 1.02 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <div className="flex items-center mb-2">
      <Icon className={`mr-2 ${className?.includes('from-orange-500') ? 'text-white' : 'text-orange-500'}`} size={20} />
      <h3 className="text-base font-bold text-white">{title}</h3>
    </div>
    <p className="text-xl font-bold text-white mb-1">{value}</p>
    <p className="text-xs text-gray-300">{description}</p>
  </motion.div>
);

const DAO = () => {
  const { address, isConnected } = useAccount();
  const [activeProposals, setActiveProposals] = useState([]);
  const [archivedProposals, setArchivedProposals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [votingPower, setVotingPower] = useState(0);
  const [utilityData, setUtilityData] = useState({
    totalProposals: 0,
    userRole: '',
    communityMembers: 0,
    recentActivity: []
  });
  const [expandedProposals, setExpandedProposals] = useState({});

  const { signMessageAsync } = useSignMessage();

  useEffect(() => {
    fetchProposals();
    fetchSpaceInfo();
    if (isConnected) {
      fetchUserRole();
      fetchRecentActivity();
    }
  }, [isConnected]);

  const fetchProposals = async () => {
    const query = `
      query {
        proposals(
          first: 100,
          skip: 0,
          where: {
            space_in: ["${SNAPSHOT_SPACE}"]
          },
          orderBy: "created",
          orderDirection: desc
        ) {
          id
          title
          body
          choices
          start
          end
          snapshot
          state
          author
          votes
          scores
          scores_total
          scores_by_strategy
        }
      }
    `;

    try {
      const response = await fetch(SNAPSHOT_API_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
      });

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

      const data = await response.json();
      const proposals = data.data.proposals;
      
      setActiveProposals(proposals.filter(p => p.state === 'active'));
      setArchivedProposals(proposals.filter(p => p.state !== 'active'));
      setUtilityData(prev => ({ ...prev, totalProposals: proposals.length }));
      setLoading(false);
    } catch (err) {
      console.error('Error fetching proposals:', err);
      setError('Failed to fetch proposals. Please try again later.');
      setLoading(false);
    }
  };

  const fetchSpaceInfo = async () => {
    const query = `
      query {
        space(id: "${SNAPSHOT_SPACE}") {
          members
        }
      }
    `;

    try {
      const response = await fetch(SNAPSHOT_API_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
      });

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

      const data = await response.json();
      setUtilityData(prev => ({ ...prev, communityMembers: data.data.space.members }));
    } catch (err) {
      console.error('Error fetching space info:', err);
    }
  };

  const fetchUserRole = async () => {
    if (!address) return;

    const query = `
      query {
        roles(where:{address:"${address}"}) {
          space
          permissions
        }
      }
    `;

    try {
      const response = await fetch(SNAPSHOT_API_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
      });

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

      const data = await response.json();
      const userRole = data.data.roles.find(role => role.space === SNAPSHOT_SPACE);
      setUtilityData(prev => ({ ...prev, userRole: userRole ? userRole.permissions.join(', ') : 'Member' }));
    } catch (err) {
      console.error('Error fetching user role:', err);
    }
  };

  const fetchRecentActivity = async () => {
    // Placeholder: In a real scenario, you'd fetch this from your API
    setUtilityData(prev => ({ ...prev, recentActivity: ['Voted on Proposal A', 'Voted on Proposal B'] }));
  };

  const handleVote = async (proposalId, choice) => {
    if (!isConnected) {
      alert('Please connect your wallet to vote.');
      return;
    }

    try {
      const message = JSON.stringify({
        space: SNAPSHOT_SPACE,
        proposal: proposalId,
        type: 'vote',
        choice,
        metadata: {}
      });

      const signature = await signMessageAsync({ message });

      const response = await fetch('https://hub.snapshot.org/api/message', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ address, msg: message, sig: signature }),
      });

      if (!response.ok) throw new Error('Failed to submit vote');

      alert('Vote cast successfully!');
      fetchProposals();
    } catch (err) {
      console.error('Error casting vote:', err);
      alert('Failed to cast vote. Please try again.');
    }
  };

  const toggleProposalExpansion = (id) => {
    setExpandedProposals(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  const ProposalCard = ({ proposal, isArchived }) => {
    const isExpanded = expandedProposals[proposal.id];

    return (
      <motion.div
        className="bg-gray-800 rounded-lg shadow-lg p-4 mb-4"
        whileHover={{ scale: 1.01 }}
        transition={{ type: "spring", stiffness: 300 }}
      >
        <div className="flex justify-between items-start mb-2">
          <h3 className="text-lg font-bold text-orange-500">{proposal.title}</h3>
          <span className={`text-xs px-2 py-1 rounded ${proposal.state === 'active' ? 'bg-green-500' : 'bg-gray-500'}`}>
            {proposal.state}
          </span>
        </div>
        <div className={`max-h-${isExpanded ? 'full' : '24'} overflow-hidden transition-all duration-300 ease-in-out`}>
          <p className="text-sm text-gray-300 mb-2">
            {isExpanded ? proposal.body : `${proposal.body.slice(0, 150)}...`}
          </p>
          {isExpanded && (
            <>
              <div className="flex justify-between items-center mb-2 text-xs text-gray-400">
                <span className="flex items-center">
                  <Clock className="mr-1" size={12} />
                  Ends: {new Date(proposal.end * 1000).toLocaleDateString()}
                </span>
                <span className="flex items-center">
                  <Users className="mr-1" size={12} />
                  {proposal.votes} votes
                </span>
              </div>
              {!isArchived && (
                <div className="flex flex-wrap gap-2 mb-2">
                  {proposal.choices.map((choice, index) => (
                    <button
                      key={index}
                      onClick={() => handleVote(proposal.id, index + 1)}
                      className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-1 px-2 rounded text-xs transition-colors duration-200"
                    >
                      {choice}
                    </button>
                  ))}
                </div>
              )}
              <div className="bg-gray-700 p-2 rounded-lg mb-2">
                <h4 className="text-sm font-bold text-orange-500 mb-1">Current Results</h4>
                {proposal.choices.map((choice, index) => (
                  <div key={index} className="mb-1">
                    <div className="flex justify-between text-xs text-gray-300 mb-1">
                      <span>{choice}</span>
                      <span>{((proposal.scores[index] / proposal.scores_total) * 100).toFixed(2)}%</span>
                    </div>
                    <div className="w-full bg-gray-600 rounded-full h-1.5">
                      <div 
                        className="bg-orange-500 h-1.5 rounded-full" 
                        style={{width: `${(proposal.scores[index] / proposal.scores_total) * 100}%`}}
                      ></div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        <button
          onClick={() => toggleProposalExpansion(proposal.id)}
          className="text-xs text-orange-500 hover:text-orange-400 flex items-center mt-2"
        >
          {isExpanded ? 'Read less' : 'Read more'}
          {isExpanded ? <ChevronUp className="ml-1" size={12} /> : <ChevronDown className="ml-1" size={12} />}
        </button>
        <a
          href={`https://snapshot.org/#/${SNAPSHOT_SPACE}/proposal/${proposal.id}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-xs text-orange-500 hover:text-orange-400 inline-flex items-center mt-2"
        >
          View on Snapshot <ExternalLink size={12} className="ml-1" />
        </a>
      </motion.div>
    );
  };

  if (loading) {
    return <LoadingAnimation />;
  }

  return (
    <div className="flex flex-col w-full bg-gray-900 text-white p-4">
      <h1 className="text-2xl md:text-4xl font-bold text-orange-500 mb-4 flex items-center">
        <VoteIcon className="mr-2" size={28} /> ICARUS DAO
      </h1>

      <div className="bg-gray-800 rounded-lg shadow-lg p-4 mb-4">
        <h2 className="text-xl font-bold text-orange-500 mb-2 flex items-center">
          <Users className="mr-2" size={20} /> Welcome to ICARUS DAO
        </h2>
        <p className="text-sm text-gray-300 mb-2">
          Shape the future of ICARUS by participating in our decentralized governance. 
          As an ICARUS token holder, you have the power to propose and vote on key decisions 
          that will guide the project's development and growth.
        </p>
        {!isConnected && (
          <div className="bg-yellow-900 border-l-4 border-yellow-500 p-2 mb-2">
            <div className="flex items-center">
              <AlertTriangle className="h-4 w-4 text-yellow-500 mr-2" />
              <p className="text-xs text-yellow-300">
                Please connect your wallet to participate in voting.
              </p>
            </div>
          </div>
        )}
        <a
          href="https://snapshot.org/#/icaruz.eth"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded inline-flex items-center text-sm transition-colors duration-200"
        >
          Visit ICARUS on Snapshot <ExternalLink size={14} className="ml-1" />
        </a>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
        <UtilityCard
          title="Voting Power"
          value={votingPower.toFixed(2)}
          description="Your current voting power"
          icon={VoteIcon}
          className="bg-gradient-to-br from-blue-500 to-purple-500"
        />
        <UtilityCard
          title="Proposal Statistics"
          value={utilityData.totalProposals}
          description="Total number of proposals"
          icon={BarChart2}
          className="bg-gradient-to-br from-orange-500 to-yellow-500"
        />
        <UtilityCard
          title="Your Role"
          value={utilityData.userRole}
          description="Your role in the ICARUS DAO"
          icon={UserCheck}
        />
        <UtilityCard
          title="Community Overview"
          value={utilityData.communityMembers}
          description="Total number of unique voters"
          icon={Users}
        />
      </div>

      <div className="flex flex-col lg:flex-row gap-4">
        <div className="w-full lg:w-3/4">
          <h2 className="text-xl md:text-2xl font-bold text-orange-500 mb-3 flex items-center">
            <Activity className="mr-2" size={24} /> Active Proposals
          </h2>
          <div className="space-y-4">
            {activeProposals.length > 0 ? (
              activeProposals.map(proposal => (
                <ProposalCard key={proposal.id} proposal={proposal} isArchived={false} />
              ))
            ) : (
              <p className="text-sm text-gray-300">No active proposals at the moment.</p>
            )}
          </div>

          <h2 className="text-xl md:text-2xl font-bold text-orange-500 mt-6 mb-3 flex items-center">
            <Archive className="mr-2" size={24} /> Archived Proposals
          </h2>
          <div className="space-y-4">
            {archivedProposals.length > 0 ? (
              archivedProposals.map(proposal => (
                <ProposalCard key={proposal.id} proposal={proposal} isArchived={true} />
              ))
            ) : (
              <p className="text-sm text-gray-300">No archived proposals available.</p>
            )}
          </div>
        </div>

        <div className="w-full lg:w-1/4">
          <div className="bg-gray-800 rounded-lg shadow-lg p-4 mb-4">
            <h3 className="text-lg font-bold text-orange-500 mb-2 flex items-center">
              <Activity className="mr-2" size={20} /> Recent Activity
            </h3>
            <ul className="text-sm text-gray-300 space-y-2 max-h-40 overflow-y-auto">
              {utilityData.recentActivity.map((activity, index) => (
                <li key={index} className="flex items-center">
                  <div className="w-2 h-2 bg-orange-500 rounded-full mr-2"></div>
                  {activity}
                </li>
              ))}
            </ul>
          </div>
          <div className="bg-gray-800 rounded-lg shadow-lg p-4">
            <h3 className="text-lg font-bold text-orange-500 mb-2">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <a
                  href="https://snapshot.org/#/icaruz.eth"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sm text-gray-300 hover:text-orange-500 flex items-center"
                >
                  <ExternalLink size={14} className="mr-2" />
                  ICARUS on Snapshot
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="text-sm text-gray-300 hover:text-orange-500 flex items-center"
                >
                  <VoteIcon size={14} className="mr-2" />
                  How to Vote
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="text-sm text-gray-300 hover:text-orange-500 flex items-center"
                >
                  <Users size={14} className="mr-2" />
                  Community Forum
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DAO;