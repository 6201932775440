import React, { useState, useEffect, useCallback } from 'react';
import { Activity, AlertTriangle, Clock, BarChart2, TrendingUp, Users, Coins, DollarSign, RefreshCw, Zap, Server, Wallet, ArrowRightLeft, PieChart as PieChartIcon, FileCode, ArrowUpRight, ArrowDownLeft } from 'lucide-react';
import { format, subDays, parseISO, isAfter } from 'date-fns';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, PieChart, Pie, Cell, LineChart, Line, Legend, Area, AreaChart } from 'recharts';
import { formatNumber, formatAddress } from '../../utils/numberUtils';
import { config } from '../../config';
import QuarkUtils from '../../utils/QuarkUtils';
import AlveyUtils from '../../utils/AlveyUtils';
import LoadingAnimation from '../LoadingAnimation';

const COLORS = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#EA80FC', '#00E676', '#FF4081', '#18FFFF'];
const CACHE_DURATION = 60 * 60 * 1000; // 1 hour in milliseconds
const BRIDGE_WALLET = '0xe641dE87F5b3539BE6C6F717069FA90F105B5B5f';
const WALV_CONTRACT = '0xCb3e9919C56efF1004E54175a01e39163a352129';
const ALV_DECIMALS = 18;

const AlveyChainMonitor = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [chainStats, setChainStats] = useState({
    totalTransactions: 0,
    totalAccounts: 0,
    totalBlocks: 0,
    averageBlockTime: 0,
    gasUsedToday: 0,
    networkUtilization: 0,
    transactionsToday: 0,
  });
  const [alveyWallets, setAlveyWallets] = useState(0);
  const [alvPrice, setALVPrice] = useState(0);
  const [transactionChart, setTransactionChart] = useState([]);
  const [topHolders, setTopHolders] = useState([]);
  const [mostTrades, setMostTrades] = useState([]);
  const [giniCoefficient, setGiniCoefficient] = useState(0);
  const [holderConcentration, setHolderConcentration] = useState({ top10: 0, top50: 0, top100: 0 });
  const [smartContractMetrics, setSmartContractMetrics] = useState({});
  const [bridgeTransactions, setBridgeTransactions] = useState([]);
  const [bridgeBalanceHistory, setBridgeBalanceHistory] = useState([]);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [mainCoinStats, setMainCoinStats] = useState({
    totalSupply: 0,
    circulatingSupply: 0,
    totalHolders: 0,
    marketCap: 0,
  });
  const [holderDistribution, setHolderDistribution] = useState([]);
  const [blockTimeDistribution, setBlockTimeDistribution] = useState([]);

  const fetchDataWithCache = useCallback(async (key, fetchFunction) => {
    const cachedData = localStorage.getItem(key);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      if (Date.now() - timestamp < CACHE_DURATION) {
        return data;
      }
    }
    const data = await fetchFunction();
    localStorage.setItem(key, JSON.stringify({ data, timestamp: Date.now() }));
    return data;
  }, []);

  const fetchData = useCallback(async () => {
    setIsRefreshing(true);
    setError(null);
    try {
      const [
        statsResponse,
        alveyWalletsResponse,
        alvPriceResponse,
        transactionChartResponse,
        addressesResponse,
        smartContractResponse,
        bridgeTransactionsResponse,
        walvTokenResponse,
        blockTimeDistributionResponse
      ] = await Promise.all([
        fetchDataWithCache('chainStats', () => AlveyUtils.getStatsCounters()),
        fetchDataWithCache('alveyWallets', () => AlveyUtils.getTokenHolders(WALV_CONTRACT)),
        QuarkUtils.getToken(config.alveychainDec, WALV_CONTRACT),
        fetchDataWithCache('transactionChart', () => AlveyUtils.getTransactionsChart()),
        fetchDataWithCache('addresses', () => AlveyUtils.getNativeCoinHolders()),
        fetchDataWithCache('smartContracts', () => AlveyUtils.getVerifiedSmartContractsCounters()),
        fetchDataWithCache('bridgeTransactions', () => AlveyUtils.getAddressTransactions(BRIDGE_WALLET, { filter: 'to | from', limit: 100 })),
        fetchDataWithCache('walvToken', () => QuarkUtils.getToken(config.alveychainDec, WALV_CONTRACT)),
        fetchDataWithCache('blockTimeDistribution', () => AlveyUtils.getBlocks({ limit: 1000 }))
      ]);

      setChainStats({
        totalTransactions: statsResponse.total_transactions || 0,
        totalAccounts: statsResponse.total_addresses || 0,
        totalBlocks: statsResponse.total_blocks || 0,
        averageBlockTime: statsResponse.average_block_time || 0,
        gasUsedToday: statsResponse.gas_used_today || 0,
        networkUtilization: statsResponse.network_utilization_percentage || 0,
        transactionsToday: statsResponse.transactions_today || 0,
      });
      setAlveyWallets(alveyWalletsResponse.total || 0);
      setALVPrice(alvPriceResponse?.price_usd || 0);
      setTransactionChart(transactionChartResponse?.chart_data || []);

      const addresses = addressesResponse.items || [];
      const sortedByBalance = addresses.sort((a, b) => parseFloat(b.coin_balance) - parseFloat(a.coin_balance));

      const totalSupply = parseFloat(walvTokenResponse?.total_supply || '1');
      const circulatingSupply = parseFloat(walvTokenResponse?.circulating_supply || '0');
      const marketCap = circulatingSupply * alvPriceResponse.price_usd;
      setMainCoinStats({
        totalSupply: Math.round(totalSupply),
        circulatingSupply: Math.round(circulatingSupply),
        totalHolders: alveyWalletsResponse.total || 0,
        marketCap: Math.round(marketCap),
      });

      setTopHolders(sortedByBalance.slice(0, 10).map(address => ({
        address: address.hash,
        balance: Math.round(parseFloat(address.coin_balance) / 10**ALV_DECIMALS),
        percentage: ((parseFloat(address.coin_balance) / totalSupply) * 100).toFixed(2),
        value: Math.round((parseFloat(address.coin_balance) / 10**ALV_DECIMALS) * alvPriceResponse.price_usd)
      })));

      setMostTrades(addresses.sort((a, b) => parseInt(b.tx_count) - parseInt(a.tx_count)).slice(0, 10).map(address => ({
        address: address.hash,
        txCount: parseInt(address.tx_count)
      })));

      const gini = calculateGiniCoefficient(sortedByBalance.map(a => parseFloat(a.coin_balance)));
      setGiniCoefficient(gini);

      const concentration = calculateHolderConcentration(sortedByBalance, totalSupply);
      setHolderConcentration(concentration);

      setSmartContractMetrics(smartContractResponse);
      
      const processedBridgeTransactions = bridgeTransactionsResponse.items
        .filter(tx => tx.timestamp)
        .slice(0, 100)
        .map(tx => ({
          hash: tx.hash,
          from: tx.from.hash,
          to: tx.to.hash,
          value: Math.round(parseFloat(tx.value) / 10**ALV_DECIMALS),
          dollarValue: Math.round((parseFloat(tx.value) / 10**ALV_DECIMALS) * alvPriceResponse.price_usd),
          isInflow: tx.to.hash.toLowerCase() === BRIDGE_WALLET.toLowerCase(),
          timestamp: new Date(tx.timestamp)
        }));
      
      setBridgeTransactions(processedBridgeTransactions);

      const bridgeHistory = calculateBridgeHistory(processedBridgeTransactions);
      setBridgeBalanceHistory(bridgeHistory);

      const distribution = calculateHolderDistribution(sortedByBalance, totalSupply);
      setHolderDistribution(distribution);

      const blockTimes = calculateBlockTimeDistribution(blockTimeDistributionResponse.items);
      setBlockTimeDistribution(blockTimes);

      setLastUpdated(new Date());
    } catch (err) {
      console.error('Error fetching Alvey chain data:', err);
      setError('Failed to fetch chain data. Please try again later.');
    } finally {
      setLoading(false);
      setIsRefreshing(false);
    }
  }, [fetchDataWithCache]);

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(() => {
      QuarkUtils.getToken(config.alveychainDec, WALV_CONTRACT)
        .then(response => setALVPrice(response?.price_usd || 0));
    }, 60000); // Update ALV price every minute

    return () => clearInterval(intervalId);
  }, [fetchData]);

  const calculateGiniCoefficient = (balances) => {
    const n = balances.length;
    if (n === 0) return 0;

    const mean = balances.reduce((a, b) => a + b, 0) / n;
    const totalAbsoluteDifference = balances.reduce((sum, balance) => {
      return sum + balances.reduce((innerSum, otherBalance) => {
        return innerSum + Math.abs(balance - otherBalance);
      }, 0);
    }, 0);

    return totalAbsoluteDifference / (2 * n * n * mean);
  };

  const calculateHolderConcentration = (sortedHolders, totalSupply) => {
    const calculatePercentage = (holders) => {
      const sum = holders.reduce((acc, holder) => acc + parseFloat(holder.coin_balance), 0);
      return ((sum / totalSupply) * 100).toFixed(2);
    };

    return {
      top10: calculatePercentage(sortedHolders.slice(0, 10)),
      top50: calculatePercentage(sortedHolders.slice(0, 50)),
      top100: calculatePercentage(sortedHolders.slice(0, 100)),
    };
  };

  const calculateBridgeHistory = (transactions) => {
    const startDate = subDays(new Date(), 7);
    let balance = 0;
    const dailyData = {};

    transactions.reverse().forEach(tx => {
      if (isAfter(tx.timestamp, startDate)) {
        const dateKey = format(tx.timestamp, 'yyyy-MM-dd');
        if (!dailyData[dateKey]) {
          dailyData[dateKey] = { inflow: 0, outflow: 0, balance: 0 };
        }
        const value = parseFloat(tx.value);
        if (tx.isInflow) {
          balance += value;
          dailyData[dateKey].inflow += value;
        } else {
          balance -= value;
          dailyData[dateKey].outflow += value;
        }
        dailyData[dateKey].balance = balance;
      }
    });

    return Object.entries(dailyData).map(([date, data]) => ({
      date,
      balance: Math.round(data.balance),
      inflow: Math.round(data.inflow),
      outflow: Math.round(data.outflow)
    }));
  };

  const calculateHolderDistribution = (holders, totalSupply) => {
    const totalHolders = holders.length;
    const brackets = [
      { name: 'Top 1%', count: Math.floor(totalHolders * 0.01) },
      { name: 'Top 1-5%', count: Math.floor(totalHolders * 0.04) },
      { name: 'Top 5-10%', count: Math.floor(totalHolders * 0.05) },
      { name: 'Top 10-25%', count: Math.floor(totalHolders * 0.15) },
      { name: 'Top 25-50%', count: Math.floor(totalHolders * 0.25) },
      { name: 'Bottom 50%', count: Math.floor(totalHolders * 0.5) }
    ];

    let startIndex = 0;
    return brackets.map(bracket => {
      const bracketHolders = holders.slice(startIndex, startIndex + bracket.count);
      const bracketBalance = bracketHolders.reduce((sum, holder) => sum + parseFloat(holder.coin_balance), 0);
      startIndex += bracket.count;
      return {
        name: bracket.name,
        value: ((bracketBalance / totalSupply) * 100).toFixed(2)
      };
    });
  };

  const calculateBlockTimeDistribution = (blocks) => {
    const blockTimes = blocks.slice(1).map((block, index) => {
      const prevBlock = blocks[index];
      return new Date(block.timestamp).getTime() - new Date(prevBlock.timestamp).getTime();
    });

    const distribution = blockTimes.reduce((acc, time) => {
      const bucket = Math.floor(time / 1000) * 1000; // Round to nearest second
      acc[bucket] = (acc[bucket] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(distribution)
      .map(([time, count]) => ({ time: parseInt(time), count }))
      .sort((a, b) => a.time - b.time);
  };

  const ALVPriceCard = ({ price }) => (
    <div className="bg-gradient-to-r from-orange-500 to-yellow-500 p-6 rounded-lg shadow-lg">
      <h3 className="text-2xl font-bold text-white mb-4 flex items-center">
        <DollarSign className="mr-2" size={24} /> ALV Price
      </h3>
      <p className="text-4xl font-bold text-white">${formatNumber(price, 'lowPriceUSD')}</p>
    </div>
  );

  const MainStatsCard = ({ chainStats, mainCoinStats }) => (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
      <h3 className="text-2xl font-bold text-orange-500 mb-4 flex items-center">
        <BarChart2 className="mr-2" size={24} /> Chain & Coin Statistics
      </h3>
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
        <div className="bg-gray-700 p-4 rounded-lg">
          <p className="text-sm text-gray-400 flex items-center">
            <Activity className="mr-2" size={16} /> Total Transactions
          </p>
          <p className="text-xl font-bold text-white">{formatNumber(chainStats.totalTransactions)}</p>
        </div>
        <div className="bg-gray-700 p-4 rounded-lg">
          <p className="text-sm text-gray-400 flex items-center">
            <Users className="mr-2" size={16} /> Total Accounts
          </p>
          <p className="text-xl font-bold text-white">{formatNumber(chainStats.totalAccounts)}</p>
        </div>
        <div className="bg-gray-700 p-4 rounded-lg">
          <p className="text-sm text-gray-400 flex items-center">
            <Clock className="mr-2" size={16} /> Avg Block Time
          </p>
          <p className="text-xl font-bold text-white">{chainStats.averageBlockTime.toFixed(2)} ms</p>
        </div>
        <div className="bg-gray-700 p-4 rounded-lg">
          <p className="text-sm text-gray-400 flex items-center">
            <Coins className="mr-2" size={16} /> Market Cap
          </p>
          <p className="text-xl font-bold text-white">${formatNumber(mainCoinStats.marketCap)}</p>
        </div>
        <div className="bg-gray-700 p-4 rounded-lg">
          <p className="text-sm text-gray-400 flex items-center">
            <Coins className="mr-2" size={16} /> Total Supply
          </p>
          <p className="text-xl font-bold text-white">{formatNumber(mainCoinStats.totalSupply)} ALV</p>
        </div>
        <div className="bg-gray-700 p-4 rounded-lg">
          <p className="text-sm text-gray-400 flex items-center">
            <Coins className="mr-2" size={16} /> Circulating Supply
          </p>
          <p className="text-xl font-bold text-white">{formatNumber(mainCoinStats.circulatingSupply)} ALV</p>
        </div>
        <div className="bg-gray-700 p-4 rounded-lg">
          <p className="text-sm text-gray-400 flex items-center">
            <Users className="mr-2" size={16} /> Total Holders
          </p>
          <p className="text-xl font-bold text-white">{formatNumber(mainCoinStats.totalHolders)}</p>
        </div>
        <div className="bg-gray-700 p-4 rounded-lg">
          <p className="text-sm text-gray-400 flex items-center">
            <Zap className="mr-2" size={16} /> Network Utilization
          </p>
          <p className="text-xl font-bold text-white">{chainStats.networkUtilization.toFixed(2)}%</p>
        </div>
      </div>
    </div>
  );

  const TransactionChartCard = ({ data }) => (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg mt-6">
      <h3 className="text-2xl font-bold text-orange-500 mb-4 flex items-center">
        <BarChart2 className="mr-2" size={24} /> Daily Transactions
      </h3>
      {data.length > 0 ? (
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={data}>
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="tx_count" fill="#F97316" />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <p className="text-gray-300">No transaction data available.</p>
      )}
    </div>
  );

  const HolderDistributionCard = ({ distribution }) => (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg mt-6">
      <h3 className="text-2xl font-bold text-orange-500 mb-4 flex items-center">
        <PieChartIcon className="mr-2" size={24} /> Holder Distribution
      </h3>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={distribution}
            cx="50%"
            cy="50%"
            labelLine={false}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
            label={({ name, value }) => `${name} ${value}%`}
          >
            {distribution.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );

  const BlockTimeDistributionCard = ({ data }) => (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg mt-6">
      <h3 className="text-2xl font-bold text-orange-500 mb-4 flex items-center">
        <Clock className="mr-2" size={24} /> Block Time Distribution
      </h3>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data}>
          <XAxis dataKey="time" tickFormatter={(value) => `${value / 1000}s`} />
          <YAxis />
          <Tooltip labelFormatter={(value) => `${value / 1000} seconds`} />
          <Bar dataKey="count" fill="#4BC0C0" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );

  const BridgeBalanceChart = ({ data }) => (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg mt-6">
      <h3 className="text-2xl font-bold text-orange-500 mb-4 flex items-center">
        <BarChart2 className="mr-2" size={24} /> Bridge ALV Balance and Flow
      </h3>
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart data={data}>
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Area type="monotone" dataKey="balance" stackId="1" stroke="#8884d8" fill="#8884d8" name="ALV Balance" />
          <Area type="monotone" dataKey="inflow" stackId="2" stroke="#82ca9d" fill="#82ca9d" name="Inflow" />
          <Area type="monotone" dataKey="outflow" stackId="2" stroke="#ffc658" fill="#ffc658" name="Outflow" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );

  const GiniCoefficientCard = ({ gini }) => (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg mt-6">
      <h3 className="text-2xl font-bold text-orange-500 mb-4 flex items-center">
        <BarChart2 className="mr-2" size={24} /> Gini Coefficient
      </h3>
      <p className="text-4xl font-bold text-white">{gini.toFixed(4)}</p>
      <p className="text-sm text-gray-400 mt-2">
        (0 = perfect equality, 1 = perfect inequality)
      </p>
    </div>
  );

  const HolderConcentrationCard = ({ concentration }) => (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg mt-6">
      <h3 className="text-2xl font-bold text-orange-500 mb-4 flex items-center">
        <PieChartIcon className="mr-2" size={24} /> Holder Concentration
      </h3>
      <div className="grid grid-cols-3 gap-4">
        <div>
          <p className="text-sm text-gray-400">Top 10 Holders</p>
          <p className="text-xl font-bold text-white">{concentration.top10}%</p>
        </div>
        <div>
          <p className="text-sm text-gray-400">Top 50 Holders</p>
          <p className="text-xl font-bold text-white">{concentration.top50}%</p>
        </div>
        <div>
          <p className="text-sm text-gray-400">Top 100 Holders</p>
          <p className="text-xl font-bold text-white">{concentration.top100}%</p>
        </div>
      </div>
    </div>
  );

  const TopHoldersCard = ({ holders }) => (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg mt-6">
      <h3 className="text-2xl font-bold text-orange-500 mb-4 flex items-center">
        <Wallet className="mr-2" size={24} /> Top 10 ALV Holders
      </h3>
      <div className="overflow-x-auto">
        <table className="min-w-full">
          <thead>
            <tr>
              <th className="px-4 py-2 text-left">Address</th>
              <th className="px-4 py-2 text-right">Balance (ALV)</th>
              <th className="px-4 py-2 text-right">% of Supply</th>
              <th className="px-4 py-2 text-right">Value (USD)</th>
            </tr>
          </thead>
          <tbody>
            {holders.map((holder, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-gray-700' : 'bg-gray-800'}>
                <td className="px-4 py-2">
                  <a href={`https://alveyscan.com/address/${holder.address}`} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
                    {formatAddress(holder.address)}
                  </a>
                </td>
                <td className="px-4 py-2 text-right">{formatNumber(holder.balance)}</td>
                <td className="px-4 py-2 text-right">{holder.percentage}%</td>
                <td className="px-4 py-2 text-right">${formatNumber(holder.value)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  const MostTradesCard = ({ traders }) => (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg mt-6">
      <h3 className="text-2xl font-bold text-orange-500 mb-4 flex items-center">
        <ArrowRightLeft className="mr-2" size={24} /> Top 10 Most Active Traders
      </h3>
      <div className="overflow-x-auto">
        <table className="min-w-full">
          <thead>
            <tr>
              <th className="px-4 py-2 text-left">Address</th>
              <th className="px-4 py-2 text-right">Transaction Count</th>
            </tr>
          </thead>
          <tbody>
            {traders.map((trader, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-gray-700' : 'bg-gray-800'}>
                <td className="px-4 py-2">
                  <a href={`https://alveyscan.com/address/${trader.address}`} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
                    {formatAddress(trader.address)}
                  </a>
                </td>
                <td className="px-4 py-2 text-right">{formatNumber(trader.txCount)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  const BridgeTransactionsCard = ({ transactions }) => (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg mt-6">
      <h3 className="text-2xl font-bold text-orange-500 mb-4 flex items-center">
        <ArrowRightLeft className="mr-2" size={24} /> Recent Bridge Transactions
      </h3>
      <div className="overflow-x-auto">
        <table className="min-w-full">
          <thead>
            <tr>
              <th className="px-4 py-2 text-left">Transaction</th>
              <th className="px-4 py-2 text-left">From/To</th>
              <th className="px-4 py-2 text-right">ALV Amount</th>
              <th className="px-4 py-2 text-right">USD Value</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((tx, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-gray-700' : 'bg-gray-800'}>
                <td className="px-4 py-2">
                  <a href={`https://alveyscan.com/tx/${tx.hash}`} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
                    {formatAddress(tx.hash)}
                  </a>
                </td>
                <td className="px-4 py-2 flex items-center">
                  {tx.isInflow ? <ArrowDownLeft className="text-green-500 mr-2" size={16} /> : <ArrowUpRight className="text-red-500 mr-2" size={16} />}
                  <a href={`https://alveyscan.com/address/${tx.isInflow ? tx.from : tx.to}`} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
                    {formatAddress(tx.isInflow ? tx.from : tx.to)}
                  </a>
                </td>
                <td className="px-4 py-2 text-right">{formatNumber(tx.value)} ALV</td>
                <td className="px-4 py-2 text-right">${formatNumber(tx.dollarValue)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  return (
    <div className="bg-gray-900 text-white p-6">
      <div className="flex justify-between items-center mb-8">
        <h2 className="text-4xl font-bold text-orange-500 flex items-center">
          <Activity className="mr-4" size={36} /> Alvey Chain Monitor
        </h2>
        <button
          onClick={fetchData}
          className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded flex items-center transition-colors duration-300"
          disabled={isRefreshing}
        >
          <RefreshCw className={`mr-2 ${isRefreshing ? 'animate-spin' : ''}`} size={20} />
          Refresh Data
        </button>
      </div>

      {error && (
        <div className="bg-red-900 border-l-4 border-red-500 text-red-300 p-4 mb-6 rounded-lg" role="alert">
          <AlertTriangle className="inline-block mr-2" size={20} />
          {error}
        </div>
      )}

      {loading ? (
        <LoadingAnimation />
      ) : (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <ALVPriceCard price={alvPrice} />
            <MainStatsCard chainStats={chainStats} mainCoinStats={mainCoinStats} />
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
            <TransactionChartCard data={transactionChart} />
            <HolderDistributionCard distribution={holderDistribution} />
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
            <BlockTimeDistributionCard data={blockTimeDistribution} />
            <BridgeBalanceChart data={bridgeBalanceHistory} />
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
            <GiniCoefficientCard gini={giniCoefficient} />
            <HolderConcentrationCard concentration={holderConcentration} />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
            <TopHoldersCard holders={topHolders} />
            <MostTradesCard traders={mostTrades} />
          </div>
          
          <BridgeTransactionsCard transactions={bridgeTransactions} />
          
          {lastUpdated && (
            <div className="text-gray-400 text-sm mt-6 flex items-center justify-end">
              <Clock size={14} className="mr-2" />
              Last updated: {format(lastUpdated, 'yyyy-MM-dd HH:mm:ss')}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AlveyChainMonitor;